<template>
	<Confirmation
		:success="isSuccess()"
		success-title="confirmation.successful-deposit"
		success-subtitle="confirmation.deposit-success-completed"
		error-title="confirmation.there-is-an-issue"
		error-subtitle="confirmation.try-again"
		:try-again-link="`/payments/deposit/${$route.params.walletId}`"
	/>
</template>

<script>
import { mapGetters } from 'vuex';
import Confirmation from '@/components/confirmation/Confirmation';

export default {
	name: 'DepositConfirmation',
	components: {
		Confirmation,
	},
	computed: {
		...mapGetters(['isEmbedded']),
	},
	methods: {
		isSuccess() {
			const status = this.getModuleStatus();
			if (status === 'error' || status === 'FAILED' || status === 'fail' || status === 'CANCELLED') {
				return false;
			}
			if (status === 'success' || status === 'SUCCESS' || status === 'COMPLETED') {
				return true;
			}
			return false;
		},
		getModuleStatus() {
			return this.$route.query.status;
		},
	},
};
</script>
